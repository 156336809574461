import { withEnhancedGSSP } from '~/src/common/services/Networking/server/withEnhancedGSSP';
import {
  currentOrders,
  getCategories,
  getCurrentOrdersQueryKey,
  getGetCategoriesQueryKey,
  getHomeQueryKey,
  home,
} from '~/src/queries/api-ecom/generated/api-ecom';
import { useGetHeadersQuery } from '~/src/queries/cms/generated/cms';
import HomeScreen from '~/src/screens/Home/HomeScreen';
import { CATALOG_QUERY_PARAMS } from '~/src/screens/Home/queries';
import { NextPage } from '~/src/typings/next';

const Home: NextPage = HomeScreen;

export const getServerSideProps = withEnhancedGSSP(async (_, { prefetch }) => {
  // Chargement de la home et de ses headers
  const pHome = prefetch(getHomeQueryKey(CATALOG_QUERY_PARAMS), () => home(CATALOG_QUERY_PARAMS));

  const pHomeHeaders = pHome.then(data => {
    const headerIds = data.headersIds;
    return prefetch(
      useGetHeadersQuery.getKey({ headers_list: headerIds }),
      useGetHeadersQuery.fetcher({ headers_list: headerIds }),
    );
  });

  // Récupération des commandes en cours
  const pCurrentOrders = prefetch(getCurrentOrdersQueryKey(), () =>
    currentOrders().catch(() => null),
  );

  // Récupération de la catégorie Home pour le SEO
  const pHomeCategory = prefetch(getGetCategoriesQueryKey({ tags: 'Home' }), () =>
    getCategories({ tags: 'Home' }),
  );

  // Attente de la fin des requêtes
  await Promise.all([pHome, pHomeHeaders, pCurrentOrders, pHomeCategory]);
});

export default Home;
